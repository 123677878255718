[data-theme-version="dark"] {
    .footer {
        background-color: transparent;

        .copyright {
            background-color: transparent;

            p {
                color: rgba(255,255,255,0.7);
            }
        }
    }
}