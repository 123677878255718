.doctor-info-details{
	@include respond('phone-land') {
		display:block !important;
	}
	.media{
		position:relative;
		img{
			@include respond('laptop') {
				width:100%;
			}
			
		}
		@include respond('phone-land') {
			float:left;
		}		
		@include respond('laptop') {
			width:80px;
			height:80px;
			margin-right: 20px;
		}
		
		i{
			width:64px;
			height:64px;
			border-radius:60px;
			border:3px solid #fff;
			line-height:58px;
			text-align:center;
			background:$primary;
			position:absolute;
			right:-15px;
			bottom:-15px;
			color:$white;
			font-size: 24px;
			@include respond('laptop') {
				width: 50px;
				height: 50px;
				font-size: 18px;
				line-height: 46px;
			
			}
			@include respond ('phone'){
				width:35px;
				height:35px;
				line-height:31px;
				fonr-size:1rem;
			}
		}
	}
	.media-body{
		padding-left:40px;
		
		@include respond('laptop') {
			padding-left:20px;
		}
		@include respond('phone-land') {
			padding-left:0;
		}
		h2{
			font-size:40px;
			line-height:1.2;
			font-weight:600;
			color:$black;
			@include respond('laptop') {
				font-size:22px;
			}
			@include respond ('phone'){
				font-size:20px;
			}
		}
		p{
			font-size:18px;
			font-weight:500;
			color:#3e4954;
		}
		span{
			color: #333333;
			
			i{
				transform: scale(1.3);
				display: inline-block;
				margin-right: 10px;
			}
		}
	}
	.star-review i {
		font-size: 22px;
		@include respond('laptop') {
			font-size: 16px;
		}
	}
	
}
.doctor-info-content{
	p{
		line-height:1.4;
	}
	
}
.review-box{
	border:1px solid #f0f0f0;
	border-radius:18px;
	padding:20px 30px 30px 30px;
	@include respond('laptop') {
		padding:15px 15px 20px 15px;
	}
	@include respond('phone-land') {
		display:block !important;
	}
	// h4{
	// 	font-size:20px;
	// }
	p{
		//font-size:14px;
		line-height:1.4;
	}
	img{
		@include respond('phone-land') {
			// width: 60px;
			// height: 60px;
			float:left;
		}
		@include respond ('phone'){
			width:100%;
			display:block;
			float:unset;
			height:150px!important;
			margin-bottom:5px;
		}
	}
	.media-footer{
		min-width: 150px;
		@include respond('laptop') {
			min-width: 110px;
		}
	}
	.star-review{
		@include respond('phone-land') {
			margin-top: 15px;
		}
		span{
			display: block;
			font-size: 24px;
			font-weight: 600;
			margin-bottom: 15px;
			line-height: 1.3;
			@include respond('phone-land') {
				font-size: 16px;
				display: inline-block;
				margin-bottom: 0;
			}
		}
		i{
		    font-size: 18px;
			margin: 0 2px;
			@include respond('laptop') {
				font-size: 16px;
				margin: 0 1px;
			}
		}
	}
	img{
		object-fit:cover;
		height:105px;
		@include custommq($max: 600px){
			// height:60px;
		}
	}
	
}
.patient-box{
	@include respond('phone-land') {
		display:block !important;
	}
	.up-sign{
		i{
			font-size:50px;
			line-height: 0.7;
		}
		@include respond('phone-land') {
			float:right;
		}
	}
	img{
		height:110px;
		@include respond('phone-land') {
			width:100px;
			float:left;
		}
	}
}
.patient-calender{
    color: #fff;
	.bootstrap-datetimepicker-widget table{
		td, 
		th{
			padding: 15px 5px;
			border-radius: $radius;
		}
		th {
			height: 20px;
			line-height: 20px;
			width: 20px;
			font-weight: 400;
			opacity: 0.7;
			font-size: 14px;
		}
		td.active, 
		td.active:hover,
		span, 
		i{
			color:$white;
		}
		thead tr:first-child th{
			font-size: 18px;
			font-weight: 600;
			opacity: 1;
		}
		
		.btn-primary{
			border:0;
			padding:10px;
		}
		.btn-primary,
		td span.active, 
		td i.active,
		thead tr:first-child th:hover,
		td span:hover, 
		td i:hover,
		td.day:hover, 
		td.hour:hover, 
		td.minute:hover, 
		td.second:hover{
			background:rgba(0,0,0,0.2); 
		}
	}
	
	.datepicker table tr td.selected, .datepicker table tr td.active.active,
	.datepicker.datepicker-dropdown td.day:hover, .datepicker.datepicker-dropdown th.next:hover, .datepicker.datepicker-dropdown th.prev:hover{
		box-shadow:unset;
		background-image: none!important;
		background-color: rgba(0, 0, 0, 0.2) !important;
		border-radius:$radius;
	}	
}
.abilities-chart{
	.ct-chart .ct-label{
		font-size:16px;
		fill:$black;
	}
	.ct-chart .ct-series.ct-series-a .ct-slice-donut{
		stroke:#209f84;
	}
	.ct-chart .ct-series.ct-series-b .ct-slice-donut{
		stroke:#07654e;
	}
	.ct-chart .ct-series.ct-series-c .ct-slice-donut{
		stroke:#93cbff;
	}
	.chart-point{
		font-size: 16px;
		justify-content: space-between;
		margin-top: 40px;
	}
	.chart-point .a,
	.chart-point .b,
	.chart-point .c{
		width: 32px;
		height: 8px;
		display: block;
		border-radius: 8px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;	
	}
	.chart-point .a{
		background:#07654e;
	}
	.chart-point .b{
		background:#209f84;
	}
	.chart-point .c{
		background:#93cbff;
	}
}

.d-review{
	.search-area{
		width:280px;
		.form-control{
			border:0;
		}
		.input-group-text{
			border:0;
		}
		@include respond('tab-port'){
			width:400px;
		}
		@include respond ('phone'){
			width:100%;
		}
	}
	
}
.doctor-list {
	.accordion-button:not(.collapsed) {
		box-shadow: none;
	}
}