.sidebar-right {
    right: -50rem;
    position: fixed;
    top: 0;
    width: 50rem;
    background-color: $white;
    margin-top: 3.5rem;
    transition: all .5s ease-in-out;
	border-radius: $radius;
	z-index: 9999;
	.bg-label-pattern{
		background: transparent;
		background-image: url('../images/pattern/pattern5.png');
		background-size: 130%;
	}
	.bootstrap-select{
		height:48px;
		border-radius: $radius;
		.btn{
		    padding:12px 15px;
			font-size:15px;
			border-color:$border;
			border-radius: $radius;
			background-color:$white!important;
			color:$black!important;
		}
	}
	.note-text{
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 20px;
		opacity: 0.5;
		font-size: 12px;
	}
    .slimScrollDiv {
        @at-root [direction="rtl"] #{&} {
            overflow: visible !important;
        }
    }
    .sidebar-right-trigger {
        position: absolute;
        z-index: 9;
        top: 8.75rem;
        right: 100%;
        background-color: var(--primary);
        color: $white;
        display: inline-block;
        height: 3rem;
        width: 3rem;
        text-align: center;
        font-size: 1.75rem;
        line-height: 3rem;
		border-radius: 5px 0 0 5px;
		box-shadow: -5px 3px 5px 0px rgba(119, 119, 119, 0.15);
		
		@include custommq($max: 1023px) {
			display:none;
		}
    }
	.sidebar-close-trigger{
		position:absolute;
		z-index: 2;
		font-size: 28px;
		top:0;
		right:-48px;
		height: 3rem;
        width: 3rem;
		line-height:3rem;
		text-align:center;
		background:rgba($black,1);
		color:$white;
	}
	&.show{
        right: 5.25rem;
		box-shadow: 0px 0px 50px rgba(0 ,0 ,0 ,0.2);
        z-index: 9999;
		.sidebar-right-trigger{
			display:none;
		}
		.bg-overlay{
			position:fixed;
			width:100%;
			cursor:pointer;
			height:100%;
			top:0;
			left:0;
			background:rgba($black,0.6);
		}
    }

    .card-tabs .nav-tabs {
        justify-content: space-between;
        position: sticky;
		border-radius:0;
        top: 0;
        background-color: $white;
        z-index: 2;

        .nav-item {
            margin-bottom: 0;
            flex: 1;

            .nav-link {
                border: 0;
                font-size: 1.125rem;
                position: relative;
                text-align: center;
                background-color: $white;
				&:hover{
					color:$black;
				}
                &::after {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    content: "";
                    background: transparent;
                }
                &.active {
                    background:var(--rgba-primary-1);
					color:var(--primary);
                    @at-root [data-theme-version="dark"] #{&} {
                        border-right: none;
                        border-left: none;
                        border-top: none;
                    }
                }
            }
        }
    }
	.sidebar-right-inner > h4{
		    padding: 10px 20px;
			display:flex;
			justify-content:space-between;
			align-items:center;
			color: $black!important;
			background: $white;
			margin: 0px 0px 0px 0px;
	}

    .tab-content {
        padding: 1.25rem 1.25rem 0;
		min-height: 480px;
		background:$white;
        .tab-pane {

            .admin-settings {
				.row > div {
					margin-bottom: 20px;
				}
                p {
                    color: #353535;
					font-weight: 500;
					margin-bottom: 8px;
					font-size: 16px;
                }

                // All color switcher scss goes here
                input[type="radio"] {
                    display: none;

                    + label {
                        display: inline-block;
                        width: 35px;    
                        height: 35px;
                        cursor: pointer;
                        transition: all .1s ease;
						border-radius: 4px;
						margin-right: 5px;
						margin-bottom: 3px;
                    }

                    &:checked {
                        + label {
                            position: relative;

                            &::after {
                                height: 33px;
                                width: 33px;
                                left: -4px;
                                top: -4px;
                                content: "";
                                position: absolute;
                                background-color: inherit;
								border-radius: 6px;
								opacity: 0.4;
                            }
                        }
                    }
                }
            }

        }
    }

   
    #nav_header, 
    #header, 
    #sidebar,
	#primary{
        @each $name, $color in $theme_backgrounds {
            &_#{$name} {
                + label {
                    background-color: $color;            
                }
            }
        }

        &_color_1 {
            + label {
                border: 1px solid #c4c4c4;
            }
        }
    }
	
	&.style-1{
		height:100vh;
		width:250px;
		margin-top:0;
		right:-250px;
		.sidebar-right-inner{
			background:$white;
		}
		.sidebar-right-trigger {
			top:12.4rem;
		}
		&.show{
			right:0;
			.sidebar-right-trigger{
				display:block;
			}
		}
	}
    [for=header_color_1], 
	[for=nav_header_color_1], 
	[for=primary_color_1], 
	[for=sidebar_color_1] {
		background: transparent;
		background-size: 130%;
		background-image: url('../images/pattern/pattern5.png');
	}
	@include respond ('tab-port'){
		width:75%;
	}
}

@keyframes bounce {
	0% {
        transform: translateX(-8%);;
        -webkit-transform: translateX(-8%);; 
        }
	50% {
        transform: translateX(8%);;
        -webkit-transform: translateX(8%);; 
        }
	100% {
        transform: translateX(-8%);; 
        -webkit-transform: translateX(-8%);; 
        }
}

@-webkit-keyframes bounce {
	0% {
        transform: translateX(-8%);;
        -webkit-transform: translateX(-8%);; 
        }
	50% {
        transform: translateX(8%);;
        -webkit-transform: translateX(8%);; 
        }
	100% {
        transform: translateY(-8%);; 
        -webkit-transform: translateY(-8%);; 
	}
}