@media (min-width: 767px) {

    [data-sidebar-style="icon-hover"][data-layout="horizontal"] {
		.header .header-content {
		    padding-left: 1.875rem;	
		}
		
	}
    [data-sidebar-style="icon-hover"][data-layout="vertical"] {
		
		.deznav{
			.metismenu{
				padding-top:30px;
				li.mm-active{
					&>ul{
						display:none;
					}
				}
			}
		}
		.icon-hover-toggle{
			.deznav{
				.metismenu{
					li.mm-active{
						&>ul{
							display:block;
						}
					}
				}
			}
		}
		
        .nav-header {
            width: 7.4rem;

            .brand-logo {
                padding-left: 30px;
				@at-root [direction="rtl"]#{&} {
					padding-left:30px;
					padding-right:30px;
				}
                .logo-abbr {
                    display: block;
                }

                .brand-title {
                    display: none;
                }
            }

            .nav-control {
                display: none;
            }
        }

        .header {
            padding-left: 6.5rem;

            @at-root [direction="rtl"]#{&} {
                padding-right: 6.5rem;
                padding-left: 0;
            }

            .header-content {
                //padding-left: 2rem;

                @at-root [direction="rtl"]#{&} {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        .deznav {
            overflow: visible;
            position: absolute;
            left: -11rem;
            // z-index: 1;
			
			@include custommq($max: 1350px) {
                left: -10rem;
            }

            @at-root [direction="rtl"]#{&} {
                left: auto;
                right: -11rem;
				@include custommq($max: 1350px) {
					right: -10rem;
				}
            }

            .nav-label {
                display: none;
            }

            .metismenu {
                &>li {
					padding: 0 20px;
                    &>a {
                        display: flex;
                        justify-content: space-between;
						padding: 15px 20px;
						border-radius: $radius;
						font-size: 16px;
						
                        &>svg,
                        &>i {
                            order: 1;
							margin-right:0;
							margin-top:0;
							padding-right: 0;
							@at-root [direction="rtl"]#{&} {
								padding-left:0;
								padding-right:0;
							}
                        }
						
                    }
					&:hover > a,
					&.mm-active > a{
						background: var(--primary);
						color:$white;
						box-shadow:0 12px 15px 0 var(--rgba-primary-1);
						i{
							color:$white;
						}
					}
					
                }

                ul {
					border-left: 0;
					padding-left:0;
					padding-right:0;
					@at-root [direction="rtl"]#{&} {
						padding-right:0;
						padding-left:0;
					}
					@at-root [data-theme-version="dark"]#{&} {
						border-color:$d-border;
					}
					&:after{
						left:auto;
						right:28px;
						@at-root [direction="rtl"]#{&} {
							left:28px;
							right:auto;
						}
					}
                    a {
                        position: relative;
						padding-left: 3.5rem;
						@at-root [direction="rtl"]#{&} {
							padding-right: 1.25rem;
						}
                        &::before {
							left: 30px;
						
                            @at-root [direction="rtl"]#{&} {
                                right: auto;
                                left:-5px;
                            }
                        }
						&:hover,
						&:focus,
						&.mm-active{
							background:transparent;
						}
                    }
                }

                .has-arrow {
                    &::after {
                        right: 5rem;
						opacity:0;
						
                        @at-root [direction="rtl"]#{&} {
                            right: auto;
                            left: 5rem;
                        }
                    }
                }
            }
			
			.copyright,
			.main-profile,
			.book-box{
				display:none;
			}
			&.mm-show{
				display:none;
			}
		}
		

        .icon-hover-toggle{

            .nav-header {
                width: 18.5rem;
				@include custommq($max: 1350px) {
					width: 17rem;
				}
                .brand-logo {
                    padding-left: 1.6rem;
    
    
                    .brand-title {
                        display: block;
                    }
                }
            }
			&.mm-show{
				display:block;
			}
            .header {
                padding-left: 0;
				
				@at-root [direction="rtl"]#{&} {
                    padding-right: 4.38rem;
                    padding-left: 0;
                }
            }

            .deznav {
                left: 0;
				//.metismenu > li > a > i{
				//	opacity:1;
				//}
				.metismenu .has-arrow:after{
					opacity:1;
				}

                @at-root [direction="rtl"]#{&} {
                    left: auto;
                    right: 0;
                }
            }
        }

        .content-body {
            margin-left: 7rem;

            @at-root [direction="rtl"]#{&} {
                margin-left: 0;
                margin-right: 7rem;
            }
        }
        .footer {
            padding-left: 7rem;

            @at-root [direction="rtl"]#{&} {
                margin-left: 0;
                margin-right: 7rem;
            }
        }
    }
}