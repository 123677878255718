@import "../abstracts/maps";
@import "./../other/animate/animate.min.css";
@import "./../other/aos/css/aos.min.css";
@import "./../other/perfect-scrollbar/css/perfect-scrollbar.css";
@import "./../other/metismenu/css/metisMenu.min.css";
* {
    outline: none;
    padding: 0;
    &::after {
        margin: 0;
        padding: 0;
    }
    &::before {
        margin: 0;
        padding: 0;
    }
	a{
		text-decoration:none;
	}
}

body {
    overflow-x: hidden;
    height: 100%;
    position: relative;
    max-width: 100%;
	font-size:1rem;
	background-color:#e5e5e5;
	@include respond('tab-port') {
		font-size:0.875rem;
	}
    
}

p {
    line-height: 1.8;
}

.box-shadow-none {
    box-shadow: none!important;
}

#main-wrapper {
    opacity: 0;
    transition: all 0.25s ease-in;
    overflow: hidden;
    position: relative;
    // background: $body-bg;
    &.show {
        opacity: 1;
    }
}
.rounded-lg{
	border-radius:$radius;
}
ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

a {
    color: $body-color;
    &:hover,
    &:focus,
    &.active {
        text-decoration: none;
    }
}
.btn-link{
	&:hover,
    &:focus,
    &.active{
		text-decoration:none;
	}
}
.w-space-no{
	white-space:nowrap;
}
// .content-body {
//     .container-fluid {
//         padding: 0 30px 0;
//         @include media-breakpoint-down(sm) {
//             padding: 0 1.5rem 0;
//             padding: 0 0.9375rem 0;
//         }
//     }
// }
.content-body {
    .container {
        margin-top: 40px;
    }
    .container-fluid {
        padding-top: 40px;
        padding-right: 40px;
        padding-left: 40px;
		@include custommq($max: 1200px) {
			padding-top: 30px;
			padding-right: 30px;
			padding-left: 30px;
		}
		@include custommq($max: 767px) {
			padding-top: 20px;
			padding-right: 20px;
			padding-left: 20px;
		}
		@include custommq($max: 575px) {
			padding-top: 15px;
			padding-right: 15px;
			padding-left: 15px;
		}
    }
}

.content-heading {
    font-size: 16px;
    margin-bottom: 1.875rem;
    margin-top: 3.125rem;
    border-bottom: 1px solid $border;
    padding-bottom: 10px;
    @at-root [direction="rtl"] #{&} {
        text-align: right;
    }
}
/*sp*/
.row.sp4,
.sp4{
	margin-left:-0.125rem;
	margin-right:-0.125rem;
	[class*="col-"]{
		padding-left:0.125rem;
		padding-right:0.125rem;
	}
}

//change bootstrap default button behaviour on :focus:active 😢
.btn {
    &-primary {
        &:not(:disabled):not(.disabled) {
            &:active,
            &.active {
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}

// Support ticket btn
.support-ticket {
    position: fixed;
    bottom: 30px;
    right: 15px;
    z-index: 999999;
}

.support-ticket-btn {
    width: 100px;
    background: #7CB442;
    animation: 0.7s ease-in-out 0s infinite alternate none running crescendo;
    border-radius: 50px;
    color: #fff;
    font-size: 8px;
    font-size: 16px;
    padding: 5px 10px 7px;
    text-align: center;
    display: inline-block;
    box-shadow: rgba(124, 180, 66, 0.7) 0px 8px 35px 0px;
    &:hover,
    &:focus {
        color: #fff;
    }
}
.text-blue  { color:$blue  ;}
.text-indigo{ color:$indigo;}
.text-purple{ color:$purple;}
.text-pink  { color:$pink  ;}
.text-red   { color:$red   ;}
.text-orange{ color:$orange;}
.text-yellow{ color:$yellow;}
.text-green { color:$green ;}
.text-teal  { color:$teal  ;}
.text-cyan  { color:$cyan  ;}

.bg-gray-dark{
	background:$color_pallate_2 !important;
}
.bg-purpal{
	background:$color_pallate_4 !important;
}
.bg-red{
	background:$color_pallate_5 !important;
}
.bg-blue-dark{
	background:$color_pallate_6 !important;
}
.bg-blue{
	background:$color_pallate_7 !important;
}
.bg-blue-light{
	background:$color_pallate_8 !important;
}
.bg-green-light{
	background:$color_pallate_9 !important;
}
.bg-green{
	background:$color_pallate_10 !important;
}
.bg-black{
	background:$black;
}
.text-black{
	color:$black !important;
}
.dz-scroll{
	position:relative;
}
.fs-14{font-size:14px; line-height:1.5;}
.fs-15{font-size:14px; line-height:1.5;}
.fs-16{font-size:16px; line-height:1.5;}
.fs-18{font-size:18px; line-height:1.5;}
.fs-20{font-size:20px; line-height:1.5;}
.fs-22{font-size:22px; line-height:1.5;}
.fs-24{font-size:24px; line-height:1.4;}
.fs-26{font-size:26px; line-height:1.4;}
.fs-28{font-size:28px; line-height:1.4;}
.fs-32{font-size:32px; line-height:1.4;}
.fs-35{font-size:35px; line-height:1.4;}
.fs-38{font-size:38px; line-height:1.3;}
.fs-40{font-size:40px; line-height:1.3;}
.fs-45{font-size:45px; line-height:1.3;}
.font-w100{font-weight:100;}
.font-w200{font-weight:200;}
.font-w300{font-weight:300;}
.font-w400{font-weight:400;}
.font-w500{font-weight:500;}
.font-w600{font-weight:600;}
.font-w700{font-weight:700;}
.font-w800{font-weight:800;}
.font-w900{font-weight:900;}
//   @keyframes crescendo {
//     0%   {transform: scale(.8);}
//     50% {transform: scale(1);}
//   }
@-webkit-keyframes crescendo {
    from {
        -webkit-transform: translateY(5px) scale(.8);
        -ms-transform: translateY(5px) scale(.8);
        transform: translateY(5px) scale(.8);
    }
    to {
        -webkit-transform: translateY(0px) scale(1);
        -ms-transform: translateY(0px) scale(1);
        transform: translateY(0px) scale(1);
    }
}

@keyframes gXGDoR {
    from {
        -webkit-transform: translateY(5px) scale(.8);
        -ms-transform: translateY(5px) scale(.8);
        transform: translateY(5px) scale(.8);
    }
    to {
        -webkit-transform: translateY(0px) scale(1);
        -ms-transform: translateY(0px) scale(1);
        transform: translateY(0px) scale(1);
    }
}

@keyframes crescendo {
    from {
        -webkit-transform: translateY(5px) scale(.8);
        -ms-transform: translateY(5px) scale(.8);
        transform: translateY(5px) scale(.8);
    }
    to {
        -webkit-transform: translateY(0px) scale(1);
        -ms-transform: translateY(0px) scale(1);
        transform: translateY(0px) scale(1);
    }
}

@keyframes gXGDoR {
    from {
        -webkit-transform: translateY(5px) scale(.8);
        -ms-transform: translateY(5px) scale(.8);
        transform: translateY(5px) scale(.8);
    }
    to {
        -webkit-transform: translateY(0px) scale(1);
        -ms-transform: translateY(0px) scale(1);
        transform: translateY(0px) scale(1);
    }
}
.media-body {
    flex: 1;
}
.height10 { height:0.625rem; }
.height20 { height:1.25rem; }
.height30 { height:1.875rem; }
.height40 { height:2.5rem; }
.height50 { height:3.125rem; }
.height60 { height:3.75rem; }
.height70 { height:4.375rem; }
.height80 { height:5rem; }
.height90 { height:5.625rem; }
.height100{ height:6.25rem; }
.height110 { height:6.875rem; }
.height120 { height:7.5rem; }
.height130 { height:8.125rem; }
.height140 { height:8.75rem; }
.height150 { height:9.375rem; }
.height160 { height:10rem; }
.height170 { height:10.625rem; }
.height180 { height:11.25rem; }
.height190 { height:11.875rem; }
.height200{ height:12.5rem; }
.height210 { height:13.125rem; }
.height220 { height:13.75rem; }
.height230 { height:14.375rem; }
.height240 { height:15rem; }
.height250 { height:15.625rem; }
.height260 { height:16.25rem; }
.height270 { height:16.875rem; }
.height280 { height:17.5rem; }
.height290 { height:18.125rem; }
.height300{ height:18.75rem; }
.height310 { height:19.375rem; }
.height320 { height:20rem; }
.height330 { height:20.625rem; }
.height340 { height:21.25rem; }
.height350 { height:21.875rem; }
.height360 { height:22.5rem; }
.height370 { height:23.125rem; }
.height380 { height:23.75rem; }
.height390 { height:24.375rem; }
.height400{ height:25rem; }
.height415{ height:25.9375rem; }
.height500{ height:31.25rem; }
.height550{ height:34.375rem; }
.height600{ height:37.5rem; }
.height630{ height:39.375rem; }
.height720{ height:45rem; }
.height750{ height:46.875rem; }
.height800{ height:50rem; }
.width10  { width:0.625rem; }
.width20  { width:1.25rem; }
.width30  { width:1.875rem; }
.width40  { width:2.5rem; }
.width50  { width:3.125rem; }
.width60  { width:3.75rem; }
.width70  { width:4.375rem; }
.width80  { width:5rem; }
.width90  { width:5.625rem; }
.width100 { width:6.25rem; }
.width110 { width:6.875rem; }
.width120 { width:7.5rem; }
.width130 { width:8.125rem; }
.width140 { width:8.75rem; }
.width150 { width:9.375rem; }
.width160 { width:10rem; }
.width170 { width:10.625rem; }
.width180 { width:11.25rem; }
.width190 { width:11.875rem; }
.width200 { width:12.5rem; }
.width210 { width:13.125rem; }
.width220 { width:13.75rem; }
.width230 { width:14.375rem; }
.width240 { width:15rem; }
.width250 { width:15.625rem; }
.width260 { width:16.25rem; }
.width270 { width:16.875rem; }
.width280 { width:17.5rem; }
.width290 { width:18.125rem; }
.width300 { width:18.75rem; }
.width310 { width:19.375rem; }
.width320 { width:20rem; }
.width330 { width:20.625rem; }
.width340 { width:21.25rem; }
.width350 { width:21.875rem; }
.width360 { width:22.5rem; }
.width370 { width:23.125rem; }
.width380 { width:23.75rem; }
.width390 { width:24.375rem; }
.width400 { width:25rem; }

::selection{
	background-color: var(--primary);
	color: #fff;
}
.form-group{
	margin-bottom:1rem;
}
label{
	display: inline-block;
    margin-bottom: 0.5rem;
}
.required{
	color:$danger;
}
textarea{
	min-height:auto!important;
	height:auto!important;
}
.pull-end{
	float:right;
}
.border-s-1{
	border-radius: 0  $radius $radius 0!important;
}
.dz-scroll{
	position:relative;
	overflow-y:scroll;
}
body > *{
   // overflow-y: scroll;
	scrollbar-width: thin;
	scrollbar-color: rgba(111, 133, 147, 0.1) #fff0;
}
::-webkit-scrollbar {
	width: 5px;
	opacity:0;
}
/* ::-webkit-scrollbar-thumb{
	background:  rgba(111, 133, 147, 0.0); 
} */
::-webkit-scrollbar-thumb{
	background:  rgba(111, 133, 147, 0.1); 
}
div, span, ol, ul {
  scrollbar-width: thin;
}